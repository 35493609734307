import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AboutMe, Main, UnderConstruction } from "./pages";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "@radix-ui/themes/styles.css";
import { Theme } from "@radix-ui/themes";
import "./theme-config.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <UnderConstruction />,
  },
  // {
  //   path: "/construction",
  //   element: <UnderConstruction />,
  // },
  // {
  //   path: "/main",
  //   element: <Main />,
  //   children: [
  //     {
  //       path: "/main/about-me",
  //       element: <AboutMe />,
  //     },
  //   ],
  // },
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Theme appearance="dark">
      <RouterProvider router={router} />
    </Theme>
  </React.StrictMode>
);
