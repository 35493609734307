import React from "react";
import s from "./underconstruction.module.css";
import { LineBreak, RightArrow, Dots, ContactModal } from "../../components";
import { Dialog } from "@radix-ui/themes";

export const UnderConstruction = () => {
  const [open, setOpen] = React.useState(false);

  return (
    <div>
      <Dots className={s["dots-bottom"]} />
      <Dots className={s["dots-top"]} />
      <div className={s.container}>
        <h1 className={s.title}>
          Senior Frontend <span className={s.accent}>{`{Engineer}`}</span>
        </h1>
        <div>
          <LineBreak />
        </div>
        <p className={s.description}>
          Hi! I'm Breeana Payton, a Senior Frontend Software Engineer. Right now I'm in the process
          rebuilding my personal site but I'd still love to connect. See my resume below or send me
          a message using the "Let's Talk" link.
        </p>
        <div className={s.ctas}>
          <div className={s["resume-btn"]}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://drive.google.com/file/d/1AwGIbFl7KvngN8di2ddmrberGJu6pUID/view?usp=sharing"
            >
              My Resume
            </a>
          </div>
          <Dialog.Root>
            <Dialog.Trigger>
              <div className={s["lets-talk-btn"]} onClick={() => setOpen(true)} tabIndex={0}>
                Let's Talk <RightArrow className={s.arrow} />
              </div>
            </Dialog.Trigger>

            <ContactModal />
          </Dialog.Root>
        </div>
      </div>
      <div className={s["top-gradient"]} />
      <div className={s["bottom-gradient"]} />
      {/* <ContactModal open={open} /> */}
    </div>
  );
};
