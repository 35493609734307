import * as React from "react";
import { Dialog, Flex, Text, TextField, Button, TextArea } from "@radix-ui/themes";
import s from "./contactModal.module.css";

export const ContactModal = () => {
  const FORM_ENDPOINT =
    "https://cors-anywhere.herokuapp.com/https://public.herotofu.com/v1/0eaa4cd0-9243-11ee-9942-617f20de8d3b";

  const [submitted, setSubmitted] = React.useState(false);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    type FormData = {
      name: string;
      email: string;
      subject: string;
      body: string;
    };

    const inputs = e.currentTarget.querySelectorAll("input, textarea");
    const data: FormData = {
      name: "",
      email: "",
      subject: "",
      body: "",
    };

    inputs.forEach((input) => {
      if (input.id) {
        // @ts-ignore - this is fine
        data[input.id] = input.value;
      }
    });

    fetch(FORM_ENDPOINT, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        setSubmitted(true);
      })
      .catch((error) => {
        console.error("There has been a problem with your fetch operation:", error);
      });
  };

  return (
    <Dialog.Content style={{ maxWidth: 600, height: "auto", margin: 40 }}>
      <Dialog.Title>Let's Chat!</Dialog.Title>

      {submitted && (
        <Text as="div" size="2" mb="1" weight="bold">
          Thanks for reaching out! I'll get back to you soon.
        </Text>
      )}

      {!submitted && (
        <form action={FORM_ENDPOINT} onSubmit={handleSubmit} method="POST">
          <Flex direction="column" gap="3">
            <label>
              <Text as="div" size="2" mb="1" weight="bold">
                Name
              </Text>
              <TextField.Input
                placeholder="Enter your full name"
                id="name"
                name="name"
                maxLength={50}
              />
            </label>
            <label>
              <Text as="div" size="2" mb="1" weight="bold">
                Email
              </Text>
              <TextField.Input
                placeholder="Enter your email"
                id="email"
                name="email"
                type="email"
                maxLength={50}
              />
            </label>
            <label>
              <Text as="div" size="2" mb="1" weight="bold">
                Subject
              </Text>
              <TextField.Input
                placeholder="Enter a subject"
                size="3"
                id="subject"
                name="subject"
                maxLength={100}
              />
            </label>

            <label>
              <Text as="div" size="2" mb="1" weight="bold">
                Message
              </Text>
              <TextArea
                placeholder="What's on your mind?"
                className={s.body}
                id="body"
                name="body"
              />
            </label>
          </Flex>

          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button variant="soft" color="gray">
                Cancel
              </Button>
            </Dialog.Close>

            <Button type="submit">Send</Button>
          </Flex>
        </form>
      )}
    </Dialog.Content>
  );
};
