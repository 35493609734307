import { SVGProps } from "react";
export const Dots = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 143 74" fill="none" {...props}>
    <circle cx="2.5" cy="2.5" r="2.5" fill="white" />
    <circle cx="25.5" cy="2.5" r="2.5" fill="white" />
    <circle cx="48.5" cy="2.5" r="2.5" fill="white" fill-opacity="0.7" />
    <circle cx="94.5" cy="2.5" r="2.5" fill="white" fill-opacity="0.4" />
    <circle cx="117.5" cy="2.5" r="2.5" fill="white" fill-opacity="0.4" />
    <circle cx="71.5" cy="2.5" r="2.5" fill="white" fill-opacity="0.7" />
    <circle cx="2.5" cy="25.5" r="2.5" fill="white" />
    <circle cx="25.5" cy="25.5" r="2.5" fill="white" />
    <circle cx="48.5" cy="25.5" r="2.5" fill="white" fill-opacity="0.7" />
    <circle cx="94.5" cy="25.5" r="2.5" fill="white" fill-opacity="0.4" />
    <circle cx="71.5" cy="25.5" r="2.5" fill="white" fill-opacity="0.7" />
    <circle cx="117.5" cy="25.5" r="2.5" fill="white" fill-opacity="0.4" />
    <circle cx="140.5" cy="2.5" r="2.5" fill="white" fill-opacity="0.15" />
    <circle cx="140.5" cy="25.5" r="2.5" fill="white" fill-opacity="0.15" />
    <circle cx="2.5" cy="48.5" r="2.5" fill="white" />
    <circle cx="25.5" cy="48.5" r="2.5" fill="white" />
    <circle cx="48.5" cy="48.5" r="2.5" fill="white" fill-opacity="0.7" />
    <circle cx="94.5" cy="48.5" r="2.5" fill="white" fill-opacity="0.4" />
    <circle cx="71.5" cy="48.5" r="2.5" fill="white" fill-opacity="0.7" />
    <circle cx="140.5" cy="48.5" r="2.5" fill="white" fill-opacity="0.15" />
    <circle cx="117.5" cy="48.5" r="2.5" fill="white" fill-opacity="0.4" />
    <circle cx="2.5" cy="71.5" r="2.5" fill="white" />
    <circle cx="25.5" cy="71.5" r="2.5" fill="white" />
    <circle cx="48.5" cy="71.5" r="2.5" fill="white" fill-opacity="0.7" />
    <circle cx="94.5" cy="71.5" r="2.5" fill="white" fill-opacity="0.4" />
    <circle cx="71.5" cy="71.5" r="2.5" fill="white" fill-opacity="0.7" />
    <circle cx="140.5" cy="71.5" r="2.5" fill="white" fill-opacity="0.15" />
    <circle cx="117.5" cy="71.5" r="2.5" fill="white" fill-opacity="0.4" />
  </svg>
);
